import React, { useState, useEffect } from 'react';
import ModeSwitch from '../components/ModeSwitch';
import StandardInputs from '../components/StandardInputs';
import SliderInput from '../components/SliderInput';
import { ToastContainer } from 'react-toastify';
import styles from '../components/styles/LeftPanel.module.css';

const LeftPanel = ({
  mode,
  setMode,
  outputState,
  handleInputChange,
  handleSubmit,
  inputState,
  isSubmitting, // Use this directly to control button state
  batchSize,
  setBatchSize,
}) => {
  const [loras, setLoras] = useState([]);

  useEffect(() => {
    async function fetchLoras() {
      try {
        const response = await fetch('https://api.blerst.com/loras');
        const data = await response.json();
        if (response.ok) {
          setLoras(data.loras);
        } else {
          console.error('Error fetching LoRAs:', data.error);
        }
      } catch (error) {
        console.error('Error fetching LoRAs:', error);
      }
    }

    fetchLoras();
  }, []);

  // Use isSubmitting directly to control the button state
  const isButtonDisabled = isSubmitting;

  const handlePresetClick = (width, height) => {
    handleInputChange('width', width);
    handleInputChange('height', height);
  };

  const generateJobName = (jobId, type = "Job") => {
    const date = new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    return `${type}_${date}_${jobId.slice(0, 8)}`;
  };

  const handleSubmitJob = () => {
    if (isButtonDisabled) return; // Prevent double submission
    handleSubmit();
  };

  const isButtonActive = (width, height) => {
    return inputState.width === width && inputState.height === height;
  };  

  const [isPromptActive, setIsPromptActive] = useState(false);
  const handlePromptFocus = () => {
    setIsPromptActive(true);
  };
  
  const handlePromptBlur = () => {
    setIsPromptActive(false);
  };
  


  return (
    <div className={styles.leftPanel}>
      <div className={styles.header}>onlyjpegs</div>
      <label className={styles.inputLabel}>LoRA</label>
      <select
        value={inputState.lora || ''}
        onChange={(e) => handleInputChange('lora', e.target.value || null)}
        className={styles.dropdown}
        disabled={isSubmitting} // Disable while submitting
      >
        <option value="">None</option>
        {loras.map((loraFile, index) => (
          <option key={index} value={loraFile}>
            {loraFile.replace('.safetensors', '').toUpperCase()}
          </option>
        ))}
      </select>

      <StandardInputs
        prompt={inputState.prompt}
        setPrompt={(value) => handleInputChange('prompt', value)}
        lora={inputState.lora}
        setLora={(value) => handleInputChange('lora', value)}
        isLoading={isSubmitting} // Disable inputs while submitting
        onFocus={handlePromptFocus} // Handle focus
        onBlur={handlePromptBlur}   // Handle blur
        isPromptActive={isPromptActive} // Pass the state to control the preview
      />


      <ModeSwitch mode={mode} setMode={setMode} isLoading={isSubmitting} className={styles.loraDropdown} />

      <label className={styles.inputLabel}>Image Size</label>

      <div className={styles.quickSelectContainer}>
      <button
        className={`${styles.quickSelectButton} ${isButtonActive(512, 512) ? styles.active : ''}`}
        onClick={() => handlePresetClick(512, 512)}
        disabled={isSubmitting} // Disable while submitting
      >
        Test 512x512
      </button>
      <button
        className={`${styles.quickSelectButton} ${isButtonActive(768, 1024) ? styles.active : ''}`}
        onClick={() => handlePresetClick(768, 1024)}
        disabled={isSubmitting} // Disable while submitting
      >
        Portrait 768x1024
      </button>
      <button
        className={`${styles.quickSelectButton} ${isButtonActive(1024, 1024) ? styles.active : ''}`}
        onClick={() => handlePresetClick(1024, 1024)}
        disabled={isSubmitting} // Disable while submitting
      >
        Square 1024x1024
      </button>
      <button
        className={`${styles.quickSelectButton} ${isButtonActive(1024, 768) ? styles.active : ''}`}
        onClick={() => handlePresetClick(1024, 768)}
        disabled={isSubmitting} // Disable while submitting
      >
        Landscape 1024x768
      </button>
    </div>


      <SliderInput
        label="Width"
        value={inputState.width}
        onChange={(value) => handleInputChange('width', value)}
        min={512}
        max={1368}
        step={256}
        isLoading={isSubmitting} // Disable slider while submitting
      />
      <SliderInput
        label="Height"
        value={inputState.height}
        onChange={(value) => handleInputChange('height', value)}
        min={512}
        max={1368}
        step={256}
        isLoading={isSubmitting} // Disable slider while submitting
      />

      <label className={styles.inputLabel}>Batch Size</label>
      <input
        type="number"
        value={batchSize}
        onChange={(e) => setBatchSize(Number(e.target.value))}
        min={1}
        max={10}
        className={styles.numberInput}
        disabled={isSubmitting} // Disable while submitting
      />

      <button onClick={handleSubmitJob} disabled={isButtonDisabled} className={styles.button}>
        {isSubmitting ? 'Submitting...' : 'Generate'}
      </button>

      <ToastContainer />
    </div>
  );
};

export default LeftPanel;

import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import axios from 'axios';
import './App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NetworkStatus from './components/NetworkStatus';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import { outputReducer } from './components/OutputReducer';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
}

function CollapseButton({ isPanelCollapsed, togglePanel }) {
  return (
    <button 
      className={`collapseButton ${isPanelCollapsed ? 'active' : ''}`} 
      onClick={togglePanel}
    >
      {isPanelCollapsed ? 'Show Jobs' : 'Hide Jobs'}
    </button>
  );
}

function App() {
  const isMobile = useIsMobile();
  const [user, setUser] = useState({
    isAuthenticated: false,
    isConnecting: false,
    address: null,
  });

  const [inputState, setInputState] = useState({
    clothing: '',
    scene: '',
    background: '',
    text: '',
    prompt: '',
    width: 512,
    height: 512,
    lora: 'None',
  });

  const [outputState, dispatchOutput] = useReducer(outputReducer, {
    imageUrl: '',
    previousImages: [],
    debugInfo: '',
    isLoading: false,
    queuePosition: null,
    totalJobs: null,
    jobId: null,
    promptId: null,
    jobs: new Map(),
  });

  const [mode, setMode] = useState('Standard');
  const [batchSize, setBatchSize] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const [showPanel, setShowPanel] = useState(!isMobile);

  const pollingIntervalRef = useRef(null);

  const handleInputChange = useCallback((field, value) => {
    setInputState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleNewImage = (newImageUrl) => {
    dispatchOutput({
      type: 'UPDATE',
      previousImages: [...outputState.previousImages, newImageUrl],
    });
  };

  const generateJobName = (prompt, jobId, type = 'Job') => {
    const date = new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const shortenedPrompt = prompt.split(' ').slice(0, 3).join('_');
    return `${type}_${shortenedPrompt}_${date}_${jobId.slice(0, 8)}`;
  };

  const handleSingleJobSubmit = useCallback(
    async (promptToUse) => {
      setIsSubmitting(true);
    
      try {
        const response = await axios.post(
          'https://api.blerst.com/submit-prompt',
          {
            prompt: promptToUse,
            width: inputState.width,
            height: inputState.height,
            lora: inputState.lora === 'None' ? null : inputState.lora,
          },
          {
            withCredentials: true,
          }
        );
    
        const jobId = response.data.jobId;
        if (jobId) {
          const jobName = generateJobName(promptToUse, jobId, mode);
    
          dispatchOutput({
            type: 'JOB_QUEUED',
            jobId: jobId,
            jobName: jobName,
            queuePosition: response.data.queuePosition,
          });
          toast.info(`Job "${jobName}" added to queue. Position: ${response.data.queuePosition}`);
          setShowPanel(true);
  
          setIsSubmitting(false);
        } else {
          toast.error('Failed to retrieve job ID from backend.');
          setIsSubmitting(false);
        }
      } catch (error) {
        dispatchOutput({ type: 'ERROR', jobId: null, message: error.message });
        toast.error('Failed to submit job: ' + error.message);
        setIsSubmitting(false);
      }
    },
    [inputState, dispatchOutput, mode]
  );
  
  const handleBatchSubmit = useCallback(async () => {
    setIsSubmitting(true);
    
    try {
      if (mode === 'Pro') {
        const prompts = inputState.prompt
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line !== '');
    
        for (const prompt of prompts) {
          for (let i = 0; i < batchSize; i++) {
            await handleSingleJobSubmit(prompt);
          }
        }
      } else {
        for (let i = 0; i < batchSize; i++) {
          await handleSingleJobSubmit(inputState.prompt);
        }
      }
    } catch (error) {
      toast.error('An error occurred during job submission.');
    } finally {
      setIsSubmitting(false);
    }
  }, [batchSize, handleSingleJobSubmit, mode, inputState.prompt]);
  
  const pollJobStatus = useCallback(async () => {
    const jobsToUpdate = [...outputState.jobs.values()].filter(
      (job) => job.status !== 'completed' && job.status !== 'failed'
    );
  
    if (jobsToUpdate.length === 0) {
      return;
    }
  
    const jobUpdates = await Promise.all(
      jobsToUpdate.map(async (job) => {
        try {
          const response = await axios.get(
            `https://api.blerst.com/job-status/${job.jobId}`,
            {
              withCredentials: true,
            }
          );
  
          const data = response.data;
          const updatedJob = {
            ...job,
            queuePosition: data.queuePosition,
            status: data.status,
            imageUrl: data.image_url || job.imageUrl,
            isProcessing: data.isProcessing,
          };
  
          if (data.promptId && data.promptId !== job.promptId) {
            updatedJob.promptId = data.promptId;
          }
  
          // Ensure UI is updated if job status changes
          if (data.image_url && job.status !== 'completed') {
            toast.success(`Your image for job ${job.jobName} is ready!`);
            handleNewImage(data.image_url);
            updatedJob.status = 'completed';
            updatedJob.isProcessing = false;
            setShowPanel(true);
          }
  
          return updatedJob;
        } catch (error) {
          console.error(`Failed to fetch job status for job ${job.jobId}:`, error);
          return job;
        }
      })
    );
  
    // Dispatch update even if only small changes occurred
    dispatchOutput({ type: 'UPDATE_JOBS', jobs: jobUpdates });
  }, [outputState.jobs, handleNewImage]);

  useEffect(() => {
    const hasActiveJobs = [...outputState.jobs.values()].some(
      (job) => job.status === 'queued' || job.status === 'processing' || job.isProcessing
    );

    if (hasActiveJobs) {
      if (!pollingIntervalRef.current) {
        pollingIntervalRef.current = setInterval(pollJobStatus, 3000);
      }
      setShowPanel(true);
    } else {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
      setShowPanel(!isMobile && outputState.previousImages.length > 0);
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [outputState.jobs, outputState.previousImages, pollJobStatus, isMobile]);

  const togglePanel = () => {
    if (isMobile) {
      setIsPanelCollapsed(!isPanelCollapsed);
    }
  };

  return (
    <div className="appContainer">
      <NetworkStatus />
      <LeftPanel
        mode={mode}
        setMode={setMode}
        inputState={inputState}
        handleInputChange={handleInputChange}
        handleSubmit={handleBatchSubmit}
        batchSize={batchSize}
        setBatchSize={setBatchSize}
        outputState={outputState}
      />
      {isMobile && (
        <CollapseButton
          isPanelCollapsed={isPanelCollapsed}
          togglePanel={togglePanel}
          className={modalOpen ? 'modalOpen' : ''}
        />
      )}
      <div className={`rightPanelContainer ${isPanelCollapsed && isMobile ? 'collapsed' : ''}`}>
        <RightPanel
          outputState={outputState}
          dispatchOutput={dispatchOutput}
          allJobs={[...outputState.jobs.values()]}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </div>
    </div>
  );
}

export default App;

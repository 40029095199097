export const outputReducer = (state, action) => {
  const calculateIsLoading = (jobs) => {
    return [...jobs.values()].some((job) => job.status === 'queued' || job.status === 'processing');
  };

  switch (action.type) {
    case 'SUBMIT':
      return {
        ...state,
        isLoading: true,
        debugInfo: 'Submitting job to queue...',
      };

    case 'JOB_QUEUED':
      const newJobState = new Map(state.jobs);
      newJobState.set(action.jobId, {
        jobId: action.jobId,
        jobName: action.jobName, 
        queuePosition: action.queuePosition,
        status: 'queued',
        imageUrl: null,
        promptId: null,
      });
      return {
        ...state,
        jobs: newJobState,
        isLoading: true,
        debugInfo: `Job ${action.jobId} queued at position ${action.queuePosition}`,
      };

    case 'UPDATE_JOBS':
      const updatedJobs = new Map(state.jobs);
      let jobsChanged = false;

      action.jobs.forEach((job) => {
        const currentJob = updatedJobs.get(job.jobId);
        // Deep comparison of the job status and other critical fields
        if (!currentJob || currentJob.status !== job.status || currentJob.queuePosition !== job.queuePosition || currentJob.imageUrl !== job.imageUrl) {
          updatedJobs.set(job.jobId, { ...currentJob, ...job });
          jobsChanged = true;
        }
      });

      if (!jobsChanged) return state;

      return {
        ...state,
        jobs: updatedJobs,
        isLoading: calculateIsLoading(updatedJobs),
        debugInfo: 'Updated job statuses.',
      };

    case 'UPDATE':
      if (
        action.previousImages === state.previousImages &&
        action.imageUrl === state.imageUrl
      ) {
        return state;
      }

      return {
        ...state,
        previousImages: action.previousImages || state.previousImages,
        imageUrl: action.imageUrl || state.imageUrl,
        debugInfo: 'Updated previousImages and/or imageUrl.',
      };

    default:
      console.warn(`Unknown action type: ${action.type}`);
      return state;
  }
};
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import styles from '../components/styles/RightPanel.module.css';

const JobsSection = ({ jobs, saveImage, openModal }) => {
  return (
    <div className={styles.jobsSection}>
      <h2>Job Feed</h2>
      <div className={styles.jobsFeed}>
        {jobs.map((job, index) => (
          <div 
            key={index} 
            className={`${styles.jobCard} ${job.status.toLowerCase() === 'completed' ? styles.completed : 
                                               job.status.toLowerCase() === 'processing' ? styles.processing : 
                                               styles.queued}`}
          >
            <p><strong>Job:</strong> {job.jobName}</p>
            {job.status.toLowerCase() !== 'completed' && (
              <p><strong>Status:</strong> {job.status}</p>
            )}
            {job.status !== 'completed' && job.queuePosition !== null && job.queuePosition !== 0 && (
              <p><strong>Queue Position:</strong> {job.queuePosition}</p>
            )}
            {job.imageUrl && (
              <div className={styles.jobImageContainer}>
                <img 
                  src={job.imageUrl} 
                  alt="Generated" 
                  className={styles.jobImage}
                  onClick={() => openModal(index)}  // Pass the correct index to openModal
                />
                <button
                  className={styles.saveButtonOverlay}
                  onClick={() => saveImage(job.imageUrl)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobsSection;

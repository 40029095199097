import React, { useCallback, useState, useMemo } from 'react';
import ImageDisplay from './ImageDisplay';
import JobsSection from './JobsSection';
import { saveAs } from 'file-saver';
import styles from '../components/styles/RightPanel.module.css';

const RightPanel = React.memo(({ outputState, dispatchOutput, allJobs, modalOpen, setModalOpen }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    const openModal = useCallback((imageIndex) => {
        setCurrentImageIndex(imageIndex);  // Set the current image index
        setModalOpen(true);
    }, [setModalOpen]);

    const closeModal = useCallback(() => {
        setModalOpen(false);
        // Do not reset currentImageIndex to persist the image
    }, [setModalOpen]);

    const saveImage = useCallback((imageUrl) => {
        if (imageUrl) {
            const fileName = getFileName(imageUrl);
            saveAs(imageUrl, fileName); // Use FileSaver.js to save the image
        }
    }, []);

    const getFileName = useCallback((str) => {
        return str.substring(str.lastIndexOf('/') + 1);
    }, []);
    
    const navigateImages = useCallback((direction) => {
        if (currentImageIndex !== null) {
            const nextIndex = (currentImageIndex + direction + outputState.previousImages.length) % outputState.previousImages.length;
            setCurrentImageIndex(nextIndex);
        }
    }, [currentImageIndex, outputState.previousImages.length]);

    const currentImageUrl = useMemo(() => {
        return currentImageIndex !== null ? outputState.previousImages[currentImageIndex] : null;
    }, [currentImageIndex, outputState.previousImages]);

    return (
        <div className={styles.rightPanel}>
            {/* Modal is always rendered but its visibility is controlled */}
            <div 
                className={`${styles.modalOverlay} ${modalOpen ? styles.showModal : styles.hideModal}`} 
                onClick={closeModal}
                style={{ display: modalOpen ? 'block' : 'none' }}
            >
                <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                    <button className={`${styles.modalButton} ${styles.closeButton}`} onClick={closeModal}>Close</button>
                    <ImageDisplay imageUrl={currentImageUrl} />
                    <div className={styles.navigation}>
                        <button
                            className={styles.modalButton}
                            onClick={() => navigateImages(-1)}
                            aria-label="Previous image"
                        >
                            Previous
                        </button>
                        <button
                            className={`${styles.modalButton} ${styles.saveButton}`}
                            onClick={() => saveImage(currentImageUrl)}
                            aria-label="Save image"
                        >
                            Save
                        </button>
                        <button
                            className={styles.modalButton}
                            onClick={() => navigateImages(1)}
                            aria-label="Next image"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            <JobsSection jobs={allJobs} saveImage={saveImage} openModal={index => openModal(index)} />

            {outputState.previousImages.length > 0 && (
                <div className={styles.thumbnails}>
                    <h2>Previous Images</h2>
                    <div className={styles.thumbnailContainer}>
                        {outputState.previousImages.map((url, index) => (
                            <img
                                key={index}
                                src={url}
                                className={styles.thumbnail}
                                alt={`Thumbnail ${index + 1}`}
                                onClick={() => openModal(index)}  // Pass the correct index to openModal
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
});

export default RightPanel;

import React from 'react';

function ModeSwitch({ mode, setMode, isLoading }) {
  const handleModeChange = (event) => {
    setMode(event.target.checked ? 'Pro' : 'Standard');
  };

  return (
    <div className="modeSwitch">
      <label>
        <input 
          type="checkbox" 
          checked={mode === 'Pro'} 
          onChange={handleModeChange} 
        />
        Pro Mode
      </label>
    </div>
  );
}

export default ModeSwitch;
